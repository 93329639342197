import React from 'react';

import { IPostCard, PostCard } from './PostCard';

import style from './SinglePostGrid.scss';

interface IProps {
  posts: Array<{
    node: IPostCard;
  }>;
}

export const SinglePostGrid = ({ posts }: IProps) => (
  <div className="container">
    <div className="row">
      <div className={style.default}>
        {posts.map(({ node }) => (
          <PostCard key={node.id} post={node} />
        ))}
      </div>
    </div>
  </div>
);

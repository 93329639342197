import React from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';
import { SinglePostGrid } from '../components/SinglePostGrid';

import style from '../styles/pages/tag.scss';

import { graphql } from 'gatsby';

export interface ITag {
  name: string;
  description?: string;
}

interface ITagProps {
  data: {
    ghostTag: ITag;
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
  pageContext?: any;
}

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */
const Tag = ({ data, location, pageContext }: ITagProps) => {
  const tag = data.ghostTag;
  const posts = data.allGhostPost.edges;

  return (
    <>
      <MetaData data={data} location={location} />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <div className={style.hero}>
          <div className="container">
            <h1>{tag.name}</h1>
            {tag.description && <p>{tag.description}</p>}
          </div>
        </div>
        <div className={style.totalCount}>
          <div className="container">
            <div className="row">
              <p>{data.allGhostPost.totalCount} Posts with this tag</p>
            </div>
          </div>
        </div>
        <div className={style.tags}>
          <SinglePostGrid posts={posts} />
        </div>
        <div className="container">
          <Pagination pageContext={pageContext} className={style.pagination} />
        </div>
        <BigBanner />
      </Layout>
    </>
  );
};

export default Tag;

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: { eq: $slug }) {
      ...GhostTagFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
